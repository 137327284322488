import { graphql } from 'gatsby';
import React from 'react';
import BlogCard from '~components/common/BlogCard';
import Container from '~components/common/Container';
import GridThree from '~components/common/GridThree';
import PageHeader from '~components/common/PageHeader';
import Layout from '~components/layouts/Default';

function Page({ location, data }) {
  const links = [
    {
      titel: 'Zähneknirschen',
      image: data.bildZaehneknirschen,
      link: 'zaehneknirschen/',
      text: 'Zähneknirschen endlich loswerden'
    },
    {
      titel: 'Schlafkrankheit',
      image: data.bildSchlafkrankheiten,
      link: 'schlafkrankheit/',
      text: 'So kannst du besser schlafen'
    }
  ];

  return (
    <Layout
      location={location}
      title="► Herausforderungen im Umgang mit Stress und Anspannung"
      desc="Unter Herausforderungen wie Knirschen oder Schlafprobleme leiden viele. Hier erhältst du Tipps und Tricks für mehr Entspannung"
      active="Herausforderungen"
    >
      <PageHeader title="Herausforderungen" breadcrumb={[{ title: 'Herausforderungen' }]} />

      <Container noMargin className="my-8">
        <GridThree itemsStart>
          {links.map((entry, i) => {
            return <BlogCard title={entry.titel} image={entry.image} link={`/herausforderungen/${entry.link}`} text={entry.text} key={`herausforderungen-link-${i}`} />;
          })}
        </GridThree>
      </Container>
    </Layout>
  );
}

export default Page;

export const query = graphql`
  query {
    bildZaehneknirschen: file(relativePath: { eq: "pages/herausforderungen/zaehneknirschen/zaehneknirschen_header.jpg" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1.5)
      }
    }
    bildSchlafkrankheiten: file(relativePath: { eq: "pages/herausforderungen/schlafkrankheit/schlafkrankheit_header.jpg" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1.5)
      }
    }
  }
`;
